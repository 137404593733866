.dropdown-cell {
  padding: 0; /* Removes cell padding */
}

.role-selector {
  display: flex;
  /* width: 100%; */
}

.flex-grow-1 {
  flex-grow: 1; /* Allows dropdown to grow and fill space */
}

.dropdown-full {
  width: 100%; /* Ensures dropdown takes full width of its container */
}

.role-selector .dropdown-toggle,
.role-selector .dropdown-menu {
  color: var(--colors-text-text-primary-900);
  font-family: var(--font-family-font-family-body);
  font-size: calc(var(--font-size-text-sm) * 1px);
  font-style: normal;
  font-weight: 500;
  line-height: calc(var(--line-height-text-sm) * 1px);
}
.role-selector .dropdown-toggle.show,
.role-selector .dropdown-toggle.show::before {
  z-index: 500 !important;
}
.role-selector .dropdown-toggle {
  padding: 0px;
}
.role-selector .dropdown-toggle::before {
  content: url("../../public/svgs/Navbar/chevron-down.svg");
  position: absolute;
  top: 15px;
  right: 12px;
  transform: translateY(-50%);
}

.role-selector .dropdown-menu {
  border-radius: calc(var(--radius-xl) * 1px);
  border: 1px solid var(--colors-border-border-primary);
  background: #fff;
  box-shadow:
    0px 1px 3px 0px var(--colors-effects-shadows-shadow-sm-01),
    0px 1px 2px 0px var(--colors-effects-shadows-shadow-sm-02);
  position: absolute;
  z-index: 500 !important;
}
.role-selector .dropdown-item {
  padding: calc(var(--spacing-md) * 1px) calc(var(--spacing-md) * 1px)
    calc(var(--spacing-md) * 1px) calc(var(--spacing-lg) * 1px);
}
.role-selector .dropdown-item:first-child {
  border-top-left-radius: calc(var(--radius-xl) * 1px);
  border-top-right-radius: calc(var(--radius-xl) * 1px);
}
.role-selector .dropdown-item:last-child {
  border-bottom-left-radius: calc(var(--radius-xl) * 1px);
  border-bottom-right-radius: calc(var(--radius-xl) * 1px);
}

body.dark-theme .role-selector .dropdown-menu {
  border: 1px solid #333741 !important;
  background: #0c111d !important;
}
body.dark-theme .role-selector .dropdown-item:hover {
  background: #161b26;
}

.user-list-table-cell-data .dropdown {
  max-width: min-content;
  padding-right: 50px;
}

.save-button {
  flex-shrink: 0; /* Prevents the save button from shrinking */
  margin-left: 10px;
  width: 25px;
  cursor: pointer;
}
.user-list-enabled-disabled-nfc-container {
  display: flex;
  gap: calc(var(--spacing-xs) * 1px);
  align-items: center;
  justify-content: center;
  padding: calc(var(--spacing-xxs) * 1px) calc(var(--spacing-sm) * 1px);
  border-radius: calc(var(--radius-sm) * 1px);
  border: 1px solid var(--colors-border-border-primary);
  box-shadow: 0px 1px 2px 0px var(--colors-effects-Shadows-shadow-xs);
  width: fit-content;
}

body.dark-theme .user-list-enabled-disabled-nfc-container {
  color: white;
  border: 1px solid var(--colors-border-border-primary);
  box-shadow: 0px 1px 2px 0px var(--colors-effects-Shadows-shadow-xs);
}

.close-button {
  flex-shrink: 0; /* Prevents the save button from shrinking */
  margin-left: 10px;
  width: 25px;
  cursor: pointer;
}
.dropdown-options-styling {
  color: white !important;
}
.dropdown-options-styling:hover {
  background-color: #404446; /* Change to your desired background color */
  color: #fff; /* Change to your desired text color */
}
.user-list-table-cell .btn-check:checked + .btn,
.user-list-table-cell .btn.active,
.user-list-table-cell .btn.show,
.user-list-table-cell .btn:first-child:active,
.user-list-table-cell :not(.btn-check) + .btn:active {
  border-color: unset;
  border: 0px solid transparent;
}

/* ----------------------------------------------------- */

.user-list-table-cell-group {
  border-left: 1px solid var(--colors-border-border-secondary);
  border-right: 1px solid var(--colors-border-border-secondary);
  border-bottom: 1px solid var(--colors-border-border-secondary);
}

body.dark-theme .user-list-table-cell-group {
  border-left: 1px solid #1f242f !important;
  border-right: 1px solid #1f242f !important;
  border-bottom: 1px solid #1f242f !important;
}

.user-list-table-cell-group:hover {
  background-color: var(--colors-background-bg-primary-hover);
  cursor: pointer;
}

body.dark-theme .user-list-table-cell-group:hover {
  background-color: #161b26;
  cursor: pointer;
}

.user-list-table-cell {
  padding: calc(var(--spacing-xl) * 1px) calc(var(--spacing-3xl) * 1px)
    calc(var(--spacing-xl) * 1px) calc(var(--spacing-3xl) * 1px);
  word-wrap: break-word !important;
  color: var(--colors-text-text-primary-900);
  font-family: var(--font-family-font-family-body);
  font-size: calc(var(--font-size-text-sm) * 1px);
  font-style: normal;
  font-weight: 500;
  line-height: calc(var(--line-height-text-sm) * 1px);
}
.table-cell-location {
  font-weight: 400;
  font-size: 14px;
  color: var(--colors-text-text-tertiary-600);
}

.main-table-cell-profile-image {
  border: 0.75px solid;
  border-color: var(
    --component-colors-components-avatars-avatar-contrast-border
  );
  border-radius: calc(var(--radius-full) * 1px);
  height: 40px;
  width: 40px;
  object-fit: cover;
}

.main-table-cell-name-text {
  color: var(--colors-text-text-primary-900);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.table-cell-profile-img-name-group {
  gap: calc(var(--spacing-lg) * 1px);
}

.user-list-table-cell-group tr,
.user-list-table-cell-group td:first-child {
  /* border-bottom-right-radius: calc(var(--radius-xl) * 1px); */
  border-left: 1px solid var(--colors-border-border-secondary);
  border-bottom: 1px solid var(--colors-border-border-secondary);
}

body.dark-theme .user-list-table-cell-group tr,
body.dark-theme .user-list-table-cell-group td:first-child {
  /* border-bottom-right-radius: calc(var(--radius-xl) * 1px); */
  border-left: 1px solid #1f242f;
  border-bottom: 1px solid #1f242f;
}

.user-list-table-cell-group tr,
.user-list-table-cell-group td:last-child {
  /* border-bottom-right-radius: calc(var(--radius-xl) * 1px); */

  border-right: 1px solid var(--colors-border-border-secondary) !important;
  border-bottom: 1px solid var(--colors-border-border-secondary);
}

body.dark-theme .user-list-table-cell-group tr,
body.dark-theme .user-list-table-cell-group td:last-child {
  /* border-bottom-right-radius: calc(var(--radius-xl) * 1px); */

  border-right: 1px solid #1f242f !important;
  border-bottom: 1px solid #1f242f;
}

.user-list-table-cell-group td:nth-child(2),
.user-list-table-cell-group td:nth-child(3),
.user-list-table-cell-group td:nth-child(4),
.user-list-table-cell-group td:nth-child(5) {
  border-right: unset;
  border-bottom: 1px solid var(--colors-border-border-secondary);
  border-left: unset;
}

body.dark-theme .user-list-table-cell-group td:nth-child(2),
body.dark-theme .user-list-table-cell-group td:nth-child(3),
body.dark-theme .user-list-table-cell-group td:nth-child(4),
body.dark-theme .user-list-table-cell-group td:nth-child(5) {
  border-right: unset;
  border-bottom: 1px solid #1f242f;
  border-left: unset;
}

.user-list-table-container tbody .table-cell-special {
  border-right: unset;
  border-bottom: 1px solid var(--colors-border-border-secondary);
  border-left: unset;
}

body.dark-theme .user-list-table-container tbody .table-cell-special {
  border-right: unset;
  border-bottom: 1px solid #1f242f;
  border-left: unset;
}

/* Target the last cell within the last row */
.user-list-table-cell-group-last tr:last-child,
.user-list-table-cell-group-last td:last-child {
  border-bottom-right-radius: calc(var(--radius-xl) * 1px);
}

.user-list-table-cell-group-last tr:last-child,
.user-list-table-cell-group-last td:first-child {
  border-bottom-left-radius: calc(var(--radius-xl) * 1px);
}
.user-list-table-cell-group-rows {
  display: none;
}
@media screen and (max-width: 1400px) {
  .user-list-table-cell-group {
    display: none;
  }
  .user-list-table-cell-group-rows {
    display: flex;
    width: 100%;
    border-bottom: 1px solid var(--colors-border-border-secondary);
  }

  body.dark-theme .user-list-table-cell-group-rows {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #1f242f;
  }

  .user-list-table-cell-first-row {
    background-color: var(--colors-background-bg-secondary);
  }

  body.dark-theme .user-list-table-cell-first-row {
    background-color: #161b26;
  }

  .user-list-table-cell-row {
    color: var(--colors-text-text-tertiary-600, #475467);

    /* Text xs/Medium */
    font-family: var(--font-family-font-family-body);
    font-size: calc(var(--font-size-text-xs) * 1px);
    font-style: normal;
    font-weight: 500;
    line-height: calc(var(--line-height-text-xs) * 1px);
    padding: calc(var(--spacing-lg) * 1px) calc(var(--spacing-3xl) * 1px);
    width: 266px;
    min-width: 266px;
    /* box-sizing: border-box; */
  }
  body.dark-theme .user-list-table-cell-row {
    color: #94969c;
  }
  .user-list-table-cell-data {
    color: var(--colors-text-text-primary-900);

    font-family: var(--font-family-font-family-body);
    font-size: calc(var(--font-size-text-sm) * 1px);
    font-style: normal;
    font-weight: 500;
    line-height: calc(var(--Line-height-text-sm) * 1px);
    padding: calc(var(--spacing-lg) * 1px) calc(var(--spacing-3xl) * 1px);
    /* max-width: calc(100% - 300px); */
    /* white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;  */
    white-space: normal; /* Allows text to wrap to the next line */
    word-wrap: break-word; /* Breaks long words to wrap them onto the next line */
    word-break: break-word; /* Ensures long words are broken correctly */
  }

  body.dark-theme .user-list-table-cell-data {
    color: #f5f5f6;
  }

  .user-list-table-cell-data .btn-check:checked + .btn,
  .user-list-table-cell-data .btn.active,
  .user-list-table-cell-data .btn.show,
  .user-list-table-cell-data .btn:first-child:active,
  .user-list-table-cell-data :not(.btn-check) + .btn:active {
    border-color: unset;
    border: 0px solid transparent;
  }
}
@media screen and (max-width: 600px) {
  .user-list-table-cell-row {
    min-width: 132px;
    width: 132px;
  }
}
@media screen and (max-width: 390px) {
}
